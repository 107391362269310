import React from 'react';

const Header = () => {
    return (
        <div style={{height: '80px', color: '#222C31', textTransform: 'uppercase', display: 'flex', justifyContent: 'center',
        alignItems: 'center', borderBottom: '1px solid #D6D6D6'}}>
            <img src="Leto logo.svg" alt="Leto logo" />
        </div>
    );
};

export default Header;