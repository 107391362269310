import axios from 'axios';
import { useEffect, useState } from 'react';
import './App.css';
import CountryItem from './components/CountryItem/CountryItem';
import Header from './components/Header/Header';

function App() {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    axios
      .get('https://api.letocaffe.com/countries')
      .then((res) => setCountries(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="App">
      <Header />
      <p
        style={{
          fontSize: '36px',
          fontWeight: '300',
          color: '#222C31',
          fontFamily: 'PoppinsLight, sans-serif',
          marginTop: '80px',
          marginBottom: '28px',
        }}
      >
        Check our online store in:
      </p>

      <CountryItem countries={countries.filter(item => item.id == 1 || item.id == 4)} />
    </div>
  );
}

export default App;
