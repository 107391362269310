import React from 'react';

const CountryItem = ({ countries }) => {
  const renderIcon = (country) => {
    if (country.name == 'United Kingdom') {
      return 'UK.svg';
    }
    if (country.name == 'Saudi Arabia') {
      return 'SAUDI.svg';
    }
    if (country.name == 'Oman') {
      return 'OM.svg';
    }
    if (country.name == 'United Arab Emirates') {
      return 'UAE.svg';
    }
  };
  return countries.map((item) => (
    <a style={{textDecoration: 'none'}} href={item.website_link}>
    <div
      style={{
        width: '596px',
        height: '120px',
        border: '1px solid #D6D6D6',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        margin: '12px auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <img src={renderIcon(item)} />
        <p
          style={{
            marginLeft: '7px',
            fontSize: '24px',
            fontWeight: '300',
            color: '#222C31',
            fontFamily: 'PoppinsLight, sans-serif',
          }}
        >
          {item.name}
        </p>
      </div>
      <a style={{textDecoration: 'none'}} href={item.website_link}>
        <img src={'arrow.svg'}/>
      </a>
    </div>
    </a>
  ));
};

export default CountryItem;
